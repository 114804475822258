<template>
	<div class="orderinfo">
		<div class="tofilm-top">
			<div class="tofilm-title">
				<div class="title">确认订单</div>
				<div class="title"></div>
				<!-- <div class="anniu"><el-button type="primary" @click="onpayorder(orderInfoData)">立即付款</el-button></div> -->
			</div>
			<div class="tofilm-prompt">
				<!-- <div class="text">配音工作时间：周一到周五9:30 - 12:30  14:00 - 18:30</div> -->
			</div>
		</div>
		<div class="saomainfo">
			<div class="wenzi">
				<div class="text">实拍订单号：{{orderInfoData.order_id}}</div>
				<div class="text" v-if="payment_method == 1">订单金额：<span>￥{{orderInfoData.cost}}</span></div>
				<div class="text" v-else>订单需积分：<span>{{orderInfoData.integral}}</span></div>
				<div class="text" v-if="!_isMobile && payment_method == 1">订单付款剩余时间：{{entermin}}分钟 {{entersec}}秒</div>
				<div class="text">下单时间：{{orderInfoData.created_at}}</div>
			</div>
			<div v-if="payment_method == 1" class="zxzhifu">
				<div class="weixinimg" v-if="_isMobile">
					<div class="text"><el-button type="success" icon="iconfont icon-xiaohao" @click="onClickJsapi">点击支付</el-button></div>
				</div>
				<div class="weixinimg" v-else>
					<img :src="'data:image/jpg;base64,' + zhifuimg" alt="">
					<div class="text"><i class="iconfont icon-weixin"></i>微信支付</div>
				</div>
			</div>
			<div v-else class="jfzhifu">
				<div class="jifen">
					<div class="text"><el-button type="success" icon="iconfont icon-xiaohao" @click="onClickByIntegral">点击积分支付</el-button></div>
				</div>
			</div>
			
		</div>
		<div class="orderinfo-bottom">
			<div class="dan">
				<div class="title">配音师：</div>
				<div class="text">{{orderInfoData.mixer}}</div>
			</div>
			<div class="dan">
				<div class="title">配音风格：</div>
				<div class="text">{{orderInfoData.style_name}}</div>
			</div>
			<div class="dan">
				<div class="title">配音时长：</div>
				<div class="text">{{orderInfoData.duration}}秒</div>
			</div>
			<div class="dan">
				<div class="title">配音文字：</div>
				<div class="text">{{orderInfoData.content}}个</div>
			</div>
			<div class="dan">
				<div class="title">备注：</div>
				<div class="text">{{orderInfoData.remark}}</div>
			</div>
		</div>
		<Payorder ref="Payorder"></Payorder>
	</div>
</template>

<script>
import Payorder from '../tofilm/payorder.vue'
export default {
	data () {
		return {
			payment_method:this.$route.query.payment_method,//进入页面判断是微信支付还是积分支付
			orderInfoData:{},//详细信息
			dingshi:null,//查询支付接口
			order_id:'',//订单号
			zhifuimg:'',
			zhifumoney:'',
			time_expire:'',

			// 倒计时
			enterday:'',
			enterhr:'',
			entermin:'',
			entersec:'',

			//移动端支付
			JsapiZhifu:{},
		}
	},
	components:{
		Payorder,
	},
	mounted(){
		this.order_id = this.$route.query.order_num
		this.onorderinfo()
	},
	//离开页面前 执行
	destroyed(){
		clearInterval(this.dingshi)
	},
	methods: {
		// 调取详细信息接口
		onorderinfo(){
			var param = {
				order_id : this.$route.query.order_num
			}
			this.$service.get(this.$api.pcGetDubOrderInfo, param, (res)=> {
				if(res.code == '200'){
					this.orderInfoData = res.data
					this.zhifuimg = res.data.qr_code
					this.zhifumoney = res.data.morny
					this.order_id = res.data.order_id
					this.time_expire = res.data.time_expire
					if(!this._isMobile){
						this.onenterQuery()
					}
					this.countdown()
				}
			})
		},
		//积分支付
		onClickByIntegral(){
			var param = {
				order_id:this.order_id,
			}
			this.$service.post(this.$api.pcDubOrderPayByIntegral,param, (res)=> {
				if(res.code == '200'){
					this.$refs.Payorder.onSuccessPay()
					this.$refs.Payorder.enterRouter('/voice')
					this.$refs.Payorder.onOrdertext('配音')
				}
			})
		},
		//跳转列表
		tofilmList(){
			this.$router.push('/tofilm/tofilmList')
		},
		//调取支付
		// onpayorder(){
		// 	var param = {
		// 		order_num:this.order_num,
		// 	}
		// 	this.$service.post(this.$api.videoOrderPayAgain,param, (res)=> {
		// 		if(res.code == '200'){
		// 			this.$refs.Payorder.onImgmorny(res.data.img,res.data.money,res.data.order_num)
		// 			this.$refs.Payorder.togorouter('/tofilm/tofilmList')
		// 		}
		// 	})
		// },
		// 确认订单掉查询支付结果
		onenterQuery(){
			if(this.payment_method == 1){
				this.dingshi = setInterval(() => {
					this.onqueryVideoOrder()
				}, 2000);
			}
		},
		// 查询支付结果
		onqueryVideoOrder(){
			var param = {
				order_num:this.order_id,
			}
			this.$service.post(this.$api.queryPeiyinOrderWechatPayResoult,param, (res)=> {
				if(res.code == '200'){
					if(res.data){
						clearInterval(this.dingshi)
						this.$refs.Payorder.onSuccessPay()
						this.$refs.Payorder.enterRouter('/voice')
						this.$refs.Payorder.onOrdertext('配音')
					}
				}
			})
		},	
		//倒计时
		countdown () {
			const end = Date.parse(new Date(this.time_expire))
			const now = Date.parse(new Date())
			const msec = end - now
			if(msec<0) return;
			let day = parseInt(msec / 1000 / 60 / 60 / 24)
			let hr = parseInt(msec / 1000 / 60 / 60 % 24)
			let min = parseInt(msec / 1000 / 60 % 60)
			let sec = parseInt(msec / 1000 % 60)
			this.enterday = day
			this.enterhr = hr > 9 ? hr : '0' + hr
			this.entermin = min > 9 ? min : '0' + min
			this.entersec = sec > 9 ? sec : '0' + sec
			const that = this
			if(min>=0 && sec>=0){
				//倒计时结束关闭订单
				if(min==0 && sec==0){

				return
				}
				setTimeout(function () {
				that.countdown()
				}, 1000)
			}
		},
		// 移动端支付点击
		onClickJsapi(){
			if(this._isMobile && this._isMicrom){
				alert('请用微信打开，否则不能在线支付')
			}
			else{
				this.onJsapiZhifu()
			}
		},
		// jsapi移动端支付接口参数收取
		onJsapiZhifu(){
			var param = {
				order_id:this.order_id,
			}
			this.$service.post(this.$api.pcDubOrderPayByJsapi,param, (res)=> {
				if(res.code == '200'){
					this.$refs.Payorder.onBridgeReady(res.data.data)
					this.$refs.Payorder.enterRouter('/voice')
					this.$refs.Payorder.onOrdertext('配音')
				}
			})
		},
		
	}
}
</script>

<style lang="scss" scoped>
.orderinfo{
	.tofilm-top{
		border-bottom: 1px solid #ededed;
		padding-bottom: 5px;
		.tofilm-title{
			display: flex;
			font-weight: 500;
			padding-bottom: 15px;
			.title{
				font-size: 22px;
			}
			.anniu{
				margin-left: 10px;
			}
		}
		.tofilm-prompt{
			display: flex;
			justify-content: space-between;
			.horn{
				padding: 5px 10px;
				background: #fefae9;
				color: #ff9a56;
			}
		}
	}
	.saomainfo{
		background: #f7f7f7;
		padding: 20px;
		display: flex;
		
		.wenzi{
			width: 700px;
			.text{
				font-size: 16px;
				height: 55px;
				display: flex;
				align-items: center;
				&:first-child{
					margin-top: 0;
				}
				span{
					color: #ff9a56;
					font-size: 34px;
					font-weight: 500;
				}
			}
		}
		.weixinimg{
			width: 180px;
			img{
				width: 180px;
				height: 180px;
			}
			.text{
				text-align: center;
				font-size: 16px;
				margin-top: 10px;
				color: #029a02;
				i{
					margin-right: 10px;
				}
			}
		}
		.jfzhifu{
			display: flex;
			align-items: center;
		}
	}
	.orderinfo-bottom{
		margin-top: 20px;
		display: flex;
		flex-wrap: wrap;
		.dan{
			display: flex;
			// height: 40px;
			// align-items: center;
			padding-right: 20px;
    		box-sizing: border-box;
			width: 33.33%;
			border-bottom: 1px solid #f9f9f9;
			padding-top: 15px ;
			padding-bottom: 15px ;
			&.saoma{
				height: 250px;
			}
			.title{
				font-size: 16px;
				color: #333;
				font-weight: 500;
				min-width: 100px;
				img{
					width: 250px;
					height: 250px;
				}
			}
			.text{
				color: #666;
				font-size: 16px;
				&:nth-child(3){
					margin-left: 10px;
				}
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.orderinfo{
		.tofilm-top{
			.tofilm-prompt{
				display: block;
				.text{
					margin-bottom: 10px;
				}
			}
		}
		.saomainfo{
			display: block;
			.weixinimg{
				margin: 0 auto;
				
			}
		}
		.orderinfo-bottom{
			display: block;
			.dan{
				width: 100%;
			}
		}
	}
}
</style>
<style lang="scss">
.orderinfo{
	.weixinimg{
		.text{
			.el-button{
				i{
					margin-right: .1rem;
				}
				span{
					font-size: .3rem;
				}
			}
		}
	}
	.jifen{
		.text{
			.el-button{
				padding:20px 30px;
				span{
					font-size: 18px;
				}
			}
		}
	}
}

</style>
